<template>
  <base-modal
    modal-title="Contact Email Address Change"
    @visible="fetchLicenseeContacts()"
    @hidden="reset()"
  >
    <template #modalBody>

      <div class="flex justify-center p-4" v-if="licenseeContactsLoading">
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
      </div>

      <div class="px-4 py-3" v-else>
        <div v-if="!fetchedLicenseeContacts.length" class="p-4 mb-2 w-full text-center">
          No Contact email addresses are available for the Licensee.
        </div>
        <base-select
          v-else
          v-model="newLicenseeContact"
          class="flex items-center text-sm text-left mb-6"
          :placeholder="submissionForm.contactEmail"
          label="Licensee Contact"
          label-class="label-w-46"
          :options="fetchedLicenseeContacts.map(e => `${e.contact_first_name} ${e.contact_last_name} (${e.contact_email_address})`)"
          :disable="!fetchedLicenseeContacts.length"
          show-default-option
        />

        <div class="text-center">
          <base-button
            class="mr-3"
            variant="btn-primary"
            :disabled="!changeContactBtnActive"
            text="Change"
            @click="changeLicenseeContact()"
          />
          <base-button
            variant="btn-link"
            text="Cancel"
            @click="handleCancel()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, defineAsyncComponent, ref, onUpdated } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';

export default {
    name: 'ChangeContactModal',

    components: {
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const { submissionForm, updateSubmissionForm } = useSubmissionForm();

        // licensee contact list logic
        const fetchedLicenseeContacts = ref([]);
        const licenseeContactsLoading = ref(false);
        const fetchLicenseeContacts = async () => {
            try {
                if (licenseeContactsLoading.value) {
                    return;
                }
                licenseeContactsLoading.value = true;
                let tmp = await store.dispatch('baLicensee/fetchLicenseeContactList', {
                    licenseeId: submissionForm.licenseeId
                });

                let includedEmails = []
                tmp = (tmp||[]).filter(e => e.contact_email_address).map(e => {
                  if (includedEmails.includes(e.contact_email_address)) {
                    return null
                  } else {
                    includedEmails.push(e.contact_email_address)
                    return e
                  }
                }).filter(e => e)

                if (tmp && tmp.length) fetchedLicenseeContacts.value = tmp

            } catch (err) {
                console.error(err);
            } finally {
                licenseeContactsLoading.value = false;
            }
        };

        // change licensee contact logic
        const newLicenseeContact = ref('');
        const changeContactBtnActive = computed(() =>
          newLicenseeContact.value &&
          !newLicenseeContact.value.includes(submissionForm.contactEmail)
        );
        const changeLicenseeContact = () => {
            try {
                let tmp = fetchedLicenseeContacts.value.find(e => newLicenseeContact.value.includes(e.contact_email_address))
                if (tmp) {
                  updateSubmissionForm({
                    contactEmail: tmp.contact_email_address,
                    contactName: `${tmp.contact_first_name} ${tmp.contact_last_name}`,
                    contactPhone: tmp.contact_phone_number,
                  })
                }
                emit('hide');
            } catch (err) {
                console.error(err);
            }
        };

        // cancel logic
        const handleCancel = () => {
            emit('hide');
        };
        const reset = () => {
            fetchedLicenseeContacts.value = []
            newLicenseeContact.value = '';
        };

        return {
            fetchedLicenseeContacts,
            fetchLicenseeContacts,

            licenseeContactsLoading,

            newLicenseeContact,

            changeContactBtnActive,
            changeLicenseeContact,

            submissionForm,

            handleCancel,
            reset
        };
    }
};
</script>
